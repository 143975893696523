<template>
  <v-dialog
    transition="fade-transition"
    fullscreen
    persistent
    v-model="isOpen"
    style="max-width: 100vw"
    hide-overlay
    :overlay-opacity="0"
    z-index="1010"
    ref="dialog"
  >
   <div class="card-overlay" @click="$refs.dialog.animateClick()" />
    <v-container
      fluid
      class="px-0"
      style="background: white; min-height: 100%; max-width: 100%"
    >
      <v-row
        class="
          pt-8
          d-flex
          justify-center
          flex-column
          align-center
          justify-center
          h-100
        "
      >
        <v-col
          cols="12"
          class="d-flex align-center flex-column onboarding-container"
        >
          <v-img
            :width="$vuetify.breakpoint.mobile ? 171 : 200"
            :height="$vuetify.breakpoint.mobile ? 60 : 60"
            contain
            :src="customLogo ? customLogo : require('@/assets/logo.svg')"
            class="mb-10"
          ></v-img>
          <v-card elevation="4" class="d-flex mx-4 mb-15">
            <v-card-title class="d-flex justify-center pr-15">
              <h1 class="font-weight-800">Choose Onboarding Process</h1>
              <v-btn
                x-small
                icon
                @click="$emit('removeModal')"
                class="close-model"
                v-if="$parent.$parent.$parent.readyToRender"
              >
                <v-icon>$close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="mb-8">
                The end-customer using PanelAlpha will have two onboarding options.
              </p>
              <div class="selection-box">
                <checkbox-big
                  :checked="selectedType === 0"
                  @check="selectedType = 0"
                >
                  <template v-slot:custom>
                    <div class="text-left" style="height: 100%">
                      <h5 class="p-1 mb-4">Quick Onboarding</h5>
                      <p class="p-2 font-weight-thin base--text">
                        <b>
                          Optimized for the user experience, similar to popular website builders.
                        </b>
                      </p>
                      <p class="p-2 font-weight-thin base--text">
                        After purchasing a simple plan without a domain, the user will be presented with a quick choice of a theme and a site name. Their site will then be created under a temporary subdomain, which they can change later with a simple CNAME setup.
                      </p>
                    </div>
                  </template>
                </checkbox-big>
                <checkbox-big
                  :checked="selectedType === 1"
                  @check="selectedType = 1"
                >
                  <template v-slot:custom>
                    <div class="text-left" style="height: 100%">
                      <h5 class="p-1 mb-4">Classic Onboarding</h5>
                      <p class="p-2 font-weight-thin base--text">
                        <b>Providing full control over the process for more advanced users.</b>
                      </p>
                      <p class="p-2 font-weight-thin base--text">
                        The user will create their instance using a more detailed form, that includes the domain, template choice or cloning their site. They can optionally choose the theme first.
                      </p>
                    </div>
                  </template>
                </checkbox-big>
              </div>
            </v-card-text>
            <v-card-actions class="pt-8">
              <v-btn
                x-large
                @click="$emit('next', selectedType)"
                color="primary"
                block
                elevation="0"
              >
                <span class="font-weight-600">
                  Try It Out!
                  <v-icon size="24">$arrowright</v-icon>
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-img
          :src="require('@/assets/illustrations/radio-lines.svg')"
          class="radio-lines"
        />
      </v-row>
    </v-container>
  </v-dialog>
</template>
  
<script>
import CheckboxBig from "../buttons/CheckboxBig.vue";
export default {
  props: {
    isOpen: Boolean,
  },
  data: function () {
    return {
      selectedType: 0,
    };
  },
  computed: {
    customLogo: function () {
      if (!this.$store.state.ThemeModule.logo) {
        return false;
      }
      return this.$store.state.ThemeModule.logo;
    },
  },
  methods: {},
  components: { CheckboxBig },
};
</script>
  
  <style lang="scss" scoped>
.close-model {
  position: absolute;
  top: 24px;
  right: 24px;
}
.onboarding-container {
  z-index: 999;
}

.radio-lines {
  position: absolute;
  top: 0;
  left: 0;
}

.v-card::v-deep {
  width: 820px;
  max-width: calc(100% - 48px);
  .v-card {
    &__text,
    &__title {
      padding-left: 48px;
      padding-right: 48px;
    }
    &__text {
      overflow: visible;
      padding-bottom: 32px;
      padding-top: 0px;
      text-align: center;
      -webkit-line-clamp: unset !important;
      .selection-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px;
        @media (max-width: 700px) {
          grid-template-columns: 1fr;
        }
      }
      > p {
        font-size: $font-size-xlg;
        line-height: $line-height-xlg;
        font-weight: $font-weight-extra-light;
        color: var(--v-text-darken1);
      }
      b {
        font-weight: $font-weight-semibold;
      }
    }
    &__title {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}
</style>